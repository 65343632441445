import React, { useRef,useEffect, useState, useMemo } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/header";
import Footer from "../components/footer";
import Form from "../components/form";
import { Link } from "gatsby";
import SocPartners from "../components/socialPartners"
import Testimonials from "../components/testimonials"

const useHover = () => {
  const [hovered, setHovered] = useState();
  
  const eventHandlers = useMemo(() => ({
    onMouseOver() { setHovered(true); },
    onMouseOut() { setHovered(false); }
  }), []);
  
  return [hovered, eventHandlers];
}

const IndexPage = () => {
  const [hovered, eventHandlers] = useHover();
  
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <Layout classmain="homepage">
      <Seo
        title='Welcome to Wishbone Media'
        metaDesciption=''
      />
      <Header/>
      <div className='homepage__hero-out'>
      <div className='homepage__hero-container container'>
        <div className='homepage__hero row'>
          <div className='intro col-md-6 col-12'>
            <div className='intro__inner'>
              <h1 className='intro__inner--title'>
                Technology Enabled Performance Advertising
              </h1>
              <p className='intro__inner--p'>End-To-End Data Driven Performance Marketing Multiplied by Robust, In-House Engineering</p>
              <a href="#contact" className='btn'>Contact us</a>
            </div>
          </div>
          <div className='intro--img col-md-6 col-12'>
            <div className='intro--img-top'>
              <StaticImage
                src="../images/out.jpg"
                quality={95}
                formats={["auto", "webp"]}
                placeholder="blurred"
                alt="Acquisition"
              />
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className='services-out' id="services">
        <div className='services container'>
          <h2 className='services__title'>Our Services</h2>
          <p className='services__subtitle'>Trust Wishbone Media to design and enhance your campaigns</p>
          <div className='services__inner row'>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <h3>Development</h3>
                <p>Product development in a box: at Wishbone Media we got everything from Business Analysis, to Design and Development under 1 roof.</p>
              </div>
            </div>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <h3>Marketing</h3>
                <p>Develop a Custom inbound or outbound campaign quickly. Ready to launch anytime</p>
              </div>
            </div>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <h3>Design</h3>
                <p>Our design is driven by user experience. The goal is to make complex look simple.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SocPartners/>
      <div className='contact' id="contact">
        <div className='contact__container container'>
          <div className='contact__row row'>
            <div className='contact__col col-md-6 col-12'>
              <p className='contact__subtitle'>Enough small talk</p>
              <h2 className='contact__title'>Let's get to work</h2>
            </div>
            <div className='contact__col col-md-6 col-12'>
              <div className='contact__form'>
                <a className='contact__phone' href="tel:7276088386">
                  <img src='/images/phone.svg'/>
                  (727) 608-8386</a>
                <a className='contact__mail' href="mailto:hello@wishbone.media">hello@wishbone.media</a>
                <p className='contact_address'>
                  7521 Paula Dr #263253<br/>
                  Tampa, FL 33685
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </Layout>
  )
}

export default IndexPage
